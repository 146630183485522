<template>
	<div class="layout-footer">
		<div>
			<img src="layout/images/logo-roma.svg" alt="roma-layout"/>
			<p>Premium PrimeVue Application Template</p>
		</div>
		<div class="footer-icons">
			<button class="p-link">
				<i class="pi pi-home"></i>
			</button>
			<button class="p-link">
				<i class="pi pi-globe"></i>
			</button>
			<button class="p-link">
				<i class="pi pi-envelope"></i>
			</button>
		</div>
	</div>
</template>

<script>
	export default {
		name: "AppFooter"
	}
</script>

<style scoped>

</style>
