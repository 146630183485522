<template>
	<div class="layout-topbar">
			<button class="p-link layout-menu-button layout-topbar-icon" @click="onMenuButtonClick">
				<i class="pi pi-bars"></i>
			</button>

			<router-link to="/" class="p-link layout-topbar-logo">
				<img id="topbar-logo" src="images/247brass-logo-ohne-schrift.svg" alt="247brass Logo"  />
			</router-link>

	</div>
</template>

<script>
	export default {
		emits: ['menubutton-click', 'topbar-menubutton-click', 'topbar-item-click', 'right-menubutton-click'],
		props: {
			topbarMenuActive: Boolean,
			activeTopbarItem: String,
			inlineUser: Boolean,
		},
		methods: {
			onMenuButtonClick(event) {
				this.$emit('menubutton-click', event);
			},
			onTopbarMenuButtonClick(event) {
				this.$emit('topbar-menubutton-click', event);
			},		
		}
	}
</script>
