<template>    
    <iframe v-if="vimeoUrl != null" :src="vimeoUrl" frameborder="0" marginheight="0" marginwidth="0" width="100%" height="100%" scrolling="auto" allow="autoplay; fullscreen" allowfullscreen></iframe>
</template>

<script>
    

	export default {		
		data() {
			return {
                vimeoUrl: null
             }
		},
        created() {
            this.$watch(() => this.$route.params, () => {
                this.updateVimeoUrl();
            })
        },
        mounted() {
            this.updateVimeoUrl();
        },
        methods: {
            updateVimeoUrl() {
                this.vimeoUrl = 'https://player.vimeo.com/video/'+this.$route.params.id;
                console.debug(this.vimeoUrl);
            },    
        }
	}

</script>

<style scoped>
iframe 
{
    position: absolute;
    display: block; 
    width: 100%; 
    border: none; 
    overflow-y: auto; 
    overflow-x: hidden;
}
#app-controllpanel {
	position: absolute;
	top:3.3%;
	right:3.3%;
}
#app-controllpanel a {
	color: #FFFFFF;
	font-size: 32px;
	text-decoration: none;
}
</style>
