<template>
	<div class="public-form-page-body">
		<div class="card public-form-page-panel p-fluid">
			<div class="public-form-page-panel-content">
				<div class="grid">
					<div class="col-12 logo-container">
						<img src="images/247brass-logo.svg" alt="247brass Handels-Gmbh Logo"/>
					</div>
					<div class="col-12 username-container">
						<label>E-Mail</label>
						<div class="public-form-page-input">
							<InputText id="input" type="text" v-model="username" />
						</div>
					</div>

					<div class="col-12 sm:col-6">
						<Button label="Signup for 247brass account" :loading="busy" @click="signUp"/>
					</div>

					<div class="col-12" >
						<transition-group name="p-message" tag="div">
							<Message v-for="msg of message" :severity="msg.severity" :key="msg.id"><b>{{msg.detail}}</b> {{msg.content}}</Message>
						</transition-group>
					</div>

					<div class="col-12 sm:col-6">
						<a href="#" @click.prevent="onBackToLoginClick">← go back to login page</a>
					</div>

				</div>
			</div>
		</div>
	</div>
</template>

<script>

	import Parse from 'parse/dist/parse.min.js';

	export default {		
		data() {
			return {
				username: '',
				busy: false,
				message: [],
				msgid: 0
			}
		},
		methods: {
			onBackToLoginClick() {
				this.$router.push({path:'/login'});
			},
			signUp() {
				this.busy = true;

				const user = new Parse.User();
				user.set("username", this.username);
				user.set("email", this.username);
				user.set("password", 'Test123!');

				user.signUp().then(
					() => {
						this.message = [{severity: 'success', detail: 'Account created', id: this.msgid++ }];
					},
					(error) => {
						this.message = [{severity: 'error', detail: error.message, id: this.msgid++ }];
					}
				).finally(() => {
					this.busy = false;
				});
			}
		}	
	}

</script>

<style>
	.p-message-close {
		min-width: inherit !important;
	}
</style>
