<template>
	<div class="public-form-page-body">
		<div class="card public-form-page-panel p-fluid">
			<div class="public-form-page-panel-content">
				<div class="grid">
					<div class="col-12 logo-container">
						<img src="images/247brass-logo.svg" alt="247brass Handels-Gmbh Logo"/>
					</div>
					<div class="col-12 username-container">
						<label>E-Mail</label>
						<div class="public-form-page-input">
							<InputText id="input" type="text" v-model="username" />
						</div>
					</div>
					<div class="col-12 password-container">
						<label>Password</label>
						<div class="public-form-page-input">
							<InputText type="password" v-model="password" /><br/>
							<div class="forgetpassword-container">
								<a href="#" @click.prevent="onResetPasswordClick">Forget Password</a>
							</div>
						</div>
					</div>
					<div class="col-12 sm:col-6">
						<Button label="Sign In" :loading="busy" @click="signIn"/>
					</div>	

					<div class="col-12" >
						<transition-group name="p-message" tag="div">
							<Message v-for="msg of message" :severity="msg.severity" :key="msg.id"><b>{{msg.detail}}</b> {{msg.content}}</Message>
						</transition-group>
					</div>
					
					<div class="col-12 sm:col-6">
						<a href="#" @click.prevent="onSignupClick">signup 247brass account</a>
					</div>

				</div>
			</div>
		</div>
	</div>
</template>

<script>

	import Parse from 'parse/dist/parse.min.js';

	export default {
		data() {
			return {
				remember: false,
				username: '',
				password: '',
				busy: false,
				message: [],
				msgid:0
			}
		},
		methods: {
			onSignupClick() {
				this.$router.push({path:'/signup'});
			},
			onResetPasswordClick() {
				this.$router.push({path:'/resetPassword'});
			},
			signIn() {
				this.busy = true;
				Parse.User.logIn(this.username, this.password, { usePost: true }).then(
					() => {
						this.$router.push(this.$route.query.redirect || '/');
					},
					(error) => {
						this.message = [{severity: 'error', detail: error.message, id: this.msgid++ }];
					}
				).finally(() => {
					this.busy = false;
				});
			}
		}	
	}

</script>

<style>
	.p-message-close {
		min-width: inherit !important;
	}
</style>
