<template>
	<div :class="containerClass" @click="onDocumentClick">
		<AppTopBarElement :topbarMenuActive="topbarMenuActive" :activeTopbarItem="activeTopbarItem" :inlineUser="inlineUser" @right-menubutton-click="onRightMenuButtonClick"
			@menubutton-click="onMenuButtonClick" @topbar-menubutton-click="onTopbarMenuButtonClick" @topbar-item-click="onTopbarItemClick"></AppTopBarElement>

		<transition name="layout-menu-container">
			<div class="layout-menu-container" @click="onMenuClick" v-show="isMenuVisible()">
				<div class="menu-scroll-content">
					<div class="layout-profile" v-if="inlineUser">
						<button class="p-link layout-profile-button"
								@click="onInlineUserClick">
							<img src="layout/images/avatar.png" alt="roma-layout"/>
							<span class="layout-profile-userinfo">
							<span class="layout-profile-name">{{ currentUser }}</span>
						</span>
						</button>
					</div>
					<AppMenu :model="menu" :layoutMode="layoutMode" :active="menuActive" :mobileMenuActive="staticMenuMobileActive" @menuitem-click="onMenuItemClick" @root-menuitem-click="onRootMenuItemClick"></AppMenu>
				</div>
			</div>
		</transition>

		<div class="layout-main">
			<div class="layout-content">
				<router-view/>
			</div>
			<AppFooter/>
		</div>


		<div class="layout-content-mask"></div>
	</div>
</template>
<script>
	import AppTopBarElement from './AppTopBarElement.vue';
	import AppMenu from './AppMenu.vue';
	import EventBus from './event-bus';
	import AppFooter from './AppFooter.vue';
	import Parse from 'parse/dist/parse.min.js';

	export default {		
		data() {
			return {
				layoutMode: 'static',
				lightMenu: false,
				staticMenuMobileActive: true,
				inlineUser: true,
				topbarMenuActive: true,
				activeTopbarItem: null,
				rightPanelMenuActive: null,
				topbarColor: 'layout-topbar-bluegrey',
				overlayMenuActive: false,
				staticMenuDesktopInactive: false,
				isRTL: false,
				menuActive: true,
				currentUser:'',
				menu: [
					{
						items: [
							{label: "Dashboard", icon: "pi pi-fw pi-home", to: "/"},
							{label: "Profil", icon: "pi pi-fw pi-user", to: "/profile"},
							{label: "Einkäufe", icon: "pi pi-fw pi-shopping-cart", to: "/orders"},
							{label: "Einstellungen", icon: "pi pi-fw pi-cog", to: "/settings"},
							{label: "Logout", icon: "pi pi-fw pi-sign-out", command: () => { this.logout(); }},
						],
					}
				]
			}
		},
		mounted() {			
			Parse.User.currentAsync().then((usr) => {			
				if(usr != null)	 {
					this.currentUser = usr.getUsername();
				}
			});
		},
		watch: {
			$route() {
				this.menuActive = false;
			}
		},		
		methods: {
			logout() {
				Parse.User.logOut().then(() => {
					this.$router.push({ path: '/login' });
				});			
			},
			onDocumentClick() {				
				if (!this.topbarItemClick) {
					this.activeTopbarItem = null;
					this.topbarMenuActive = false;
				}

				if (!this.rightMenuClick) {
					this.rightPanelMenuActive = false;
				}

				if (!this.userMenuClick && this.isSlim() && !this.isMobile()) {
					this.inlineUserMenuActive = false;
				}

				if (!this.menuClick) {
					if (this.isHorizontal() || this.isSlim()) {
						this.menuActive = false;
					}

					if (this.overlayMenuActive || this.staticMenuMobileActive) {
						this.hideOverlayMenu();
					}

					EventBus.emit('reset-active-index');
					this.unblockBodyScroll();
				}

				if(this.userMenuClick) {
					this.menuActive = false;
					EventBus.emit('reset-active-index');
				}

				this.topbarItemClick = false;
				this.menuClick = false;
				this.rightMenuClick = false;
				this.userMenuClick = false;
			},
			onMenuButtonClick(event) {
				this.menuClick = true;
				this.topbarMenuActive = false;
				this.rightPanelMenuActive = false;

				if (this.layoutMode === 'overlay') {
					this.overlayMenuActive = !this.overlayMenuActive;
				}

				if (this.isDesktop())
					this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive;
				else {
					this.staticMenuMobileActive = !this.staticMenuMobileActive;
					if (this.staticMenuMobileActive) {
						this.blockBodyScroll();
					} else {
						this.unblockBodyScroll();
					}
				}

				event.preventDefault();
			},
			onTopbarMenuButtonClick(event) {
				this.topbarItemClick = true;
				this.topbarMenuActive = !this.topbarMenuActive;
				this.hideOverlayMenu();
				event.preventDefault();
			},
			onTopbarItemClick(event) {
				this.topbarItemClick = true;

				if (this.activeTopbarItem === event.item)
					this.activeTopbarItem = null;
				else
					this.activeTopbarItem = event.item;

				event.originalEvent.preventDefault();
			},
			onMenuClick() {
				this.menuClick = true;
			},
			onInlineUserClick() {
				this.userMenuClick = true;
				this.inlineUserMenuActive = !this.inlineUserMenuActive;
			},
			blockBodyScroll() {
                this.addClass(document.body, 'blocked-scroll');
			},
			unblockBodyScroll() {
                this.removeClass(document.body, 'blocked-scroll');
			},
			onRightMenuButtonClick(event) {
				this.rightMenuClick = true;
				this.rightPanelMenuActive = !this.rightPanelMenuActive;

				this.hideOverlayMenu();

				event.preventDefault();
			},
			onRightMenuClick() {
				this.rightMenuClick = true;
			},
			hideOverlayMenu() {
				this.overlayMenuActive = false;
				this.staticMenuMobileActive = false;
			},
			onMenuItemClick(event) {
				if (!event.item.items) {
					EventBus.emit('reset-active-index');
					this.hideOverlayMenu();
				}
				if (!event.item.items && (this.isHorizontal() || this.isSlim())) {
					this.menuActive = false;
				}
			},
			onRootMenuItemClick() {
				this.menuActive = !this.menuActive;
			},
			isTablet() {
				let width = window.innerWidth;
				return width <= 1024 && width > 640;
			},
			isDesktop() {
				return window.innerWidth > 896;
			},
			isMobile() {
				return window.innerWidth <= 1025;
			},
			isHorizontal() {
				return this.layoutMode === 'horizontal';
			},
			isSlim() {
				return this.layoutMode === 'slim';
			},
			isMenuVisible() {
				if (this.isDesktop()) {
					if (this.layoutMode === 'static')
						return !this.staticMenuDesktopInactive;
					else if (this.layoutMode === 'overlay')
						return this.overlayMenuActive;
					else
						return true;
				} else {
					return true;
				}
			},
			onLayoutChange(layoutMode) {
				this.layoutMode = layoutMode;
				this.staticMenuDesktopInactive = false;
				this.overlayMenuActive = false;

				if (this.isSlim() || this.isHorizontal()) {
					this.inlineUser = false;
					this.inlineUserMenuActive = false;
				}
			},
			onMenuColorChange(menuColor) {
				this.lightMenu = menuColor;
			},
			onProfileModeChange(profileMode) {
				this.inlineUser = profileMode;
			},
			onChangeOrientation(orientation) {
				this.isRTL = orientation;
			},
			onTopbarColorChange(topbarColor, logo) {
				this.topbarColor = topbarColor;
				const topbarLogoLink = document.getElementById('topbar-logo');
				topbarLogoLink.src = 'layout/images/' + logo + '.svg';
			},
			onThemeChange(theme) {
				this.theme = theme;
				this.changeStyleSheetUrl('layout-css', theme, 'layout');
				this.changeStyleSheetUrl('theme-css', theme, 'theme');
			},
			changeStyleSheetUrl(id, value, prefix) {
				let element = document.getElementById(id);
				let urlTokens = element.getAttribute('href').split('/');
				urlTokens[urlTokens.length - 1] = prefix + '-' + value + '.css';
				let newURL = urlTokens.join('/');

				this.replaceLink(element, newURL);
			},
			replaceLink(linkElement, href) {
				const id = linkElement.getAttribute('id');
				const cloneLinkElement = linkElement.cloneNode(true);

				cloneLinkElement.setAttribute('href', href);
				cloneLinkElement.setAttribute('id', id + '-clone');

				linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);

				cloneLinkElement.addEventListener('load', () => {
					linkElement.remove();
					cloneLinkElement.setAttribute('id', id);
				});
			},
            addClass(element, className) {
                if (element.classList)
                    element.classList.add(className);
                else
                    element.className += ' ' + className;
            },
            removeClass(element, className) {
                if (element.classList)
                    element.classList.remove(className);
                else
                    element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
            }
		},
		computed: {
			containerClass() {
				return ['layout-wrapper', {
					'layout-horizontal': this.layoutMode === 'horizontal',
					'layout-overlay': this.layoutMode === 'overlay',
					'layout-static': this.layoutMode === 'static',
					'layout-slim': this.layoutMode === 'slim',
					'layout-menu-light': this.lightMenu === true,
					'layout-menu-dark': this.lightMenu === false,
					'layout-overlay-active': this.overlayMenuActive,
					'layout-mobile-active': this.staticMenuMobileActive,
					'layout-static-inactive': this.staticMenuDesktopInactive,
					'layout-rtl': this.isRTL,
					'p-input-filled': this.$primevue.config.inputStyle === 'filled',
					'p-ripple-disabled': this.$primevue.config.ripple === false
				}, this.topbarColor];
			}
		},	
		components: {
			'AppTopBarElement': AppTopBarElement,
			'AppMenu': AppMenu,
			'AppFooter': AppFooter
		}
	}
</script>
