import {createRouter, createWebHashHistory} from 'vue-router';
import Parse from 'parse/dist/parse.min.js';

const routes = [
	{
		path: '/',
		name: 'dashboard',
		exact: true,
		component: () => import('./components/Dashboard.vue')
	},
	{
		path: '/profile',
		name: 'profile',
		component: () => import('./components/Profile.vue')
	},  
	{
		path: '/orders',
		name: 'orders',
		component: () => import('./components/Orders.vue')
	},    
	{
		path: '/settings',
		name: 'settings',
		component: () => import('./components/Settings.vue')
	},
    {
        path: '/player/:id',
        name: 'player',
        component: () => import('./pages/Player.vue')
    },  
    {
        path: '/login',
        name: 'login',
        meta: {public: true},
        component: () => import('./pages/Login.vue')
    },
    {
        path: '/signup',
        name: 'signup',
        meta: {public: true},
        component: () => import('./pages/Signup.vue')
    },  
    {
        path: '/resetPassword',
        name: 'resetPassword',
        meta: {public: true},
        component: () => import('./pages/ResetPassword.vue')
    },   
    {
        path: '/error',
        name: 'error',
        meta: {public: true},
        component: () => import('./pages/Error.vue')
    },
    {
        path: '/notfound',
        name: 'notfound',
        meta: {public: true},
        component: () => import('./pages/NotFound.vue')
    },
    {
        path: '/access',
        name: 'access',
        meta: {public: true},
        component: () => import('./pages/Access.vue')
    }
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
	scrollBehavior () {
        return { left: 0, top: 0 };
    },
});
router.beforeEach(async (to) => {

    if(to.meta && to.meta.public === true) {
        return true;
    }

    return await Parse.User.currentAsync().then((user) => {
        if(user) {
            // authenticated
            if(to.name == 'login') {
                // redirect to dashboard if authenticated user targets login
                router.push({path: '/', replace: true});
                return false;
            }
            return true;
        } else {
            // not authenticated          
            router.push({path: '/login', replace: true, query: { redirect: to.path }});
            return false;
        }
    }).catch(() => {
        router.push({name: 'login'});
        console.warn("-------------------------------");
        return false;
    });
});

export default router;